/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
#security_hotspots .filter-bar-outer {
  height: 62px;
}

#security_hotspots .filter-bar {
  position: fixed;
  background-color: #f3f3f3;
  z-index: 55;
  left: 0;
  right: 0;
}

#security_hotspots .filter-bar-inner {
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
}

#security_hotspots .layout-page-side,
#security_hotspots .layout-page-side-outer {
  width: calc(50vw - 330px);
}

#security_hotspots .layout-page-side-inner {
  margin-left: calc(50vw - 645px);
}

#security_hotspots .layout-page-main {
  padding: 0;
}

@media (max-width: 1320px) {
  #security_hotspots .layout-page-side-outer,
  #security_hotspots .layout-page-side {
    width: 316px;
  }

  #security_hotspots .layout-page-side-inner {
    margin-left: 0;
  }
}
