/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.account-container {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.account-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}

.account-nav {
  float: right;
  padding-top: 11px;
}

.account-user {
  float: left;
}

.account-user h1 {
  line-height: 60px;
}

.account-user-avatar {
  margin-right: 20px;
}

.account-user-avatar > img {
  border-radius: 60px;
}

.account-user-avatar:empty {
  display: none;
}

.account-body {
  padding: 40px 0;
}

.account-profile .boxed-group-inner:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.notifications-table {
  margin-top: -16px;
}

.notifications-add-project-no-search-results {
  padding: 8px;
}

.notifications-add-project-search-results li {
  padding: 8px;
  cursor: pointer;
}

.notifications-add-project-search-results li:hover,
.notifications-add-project-search-results li.active {
  background-color: #f3f3f3;
}
