/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.issues-main-header .component-name {
  line-height: 24px;
}

.issues-main-header-spinner {
  margin-right: 2px;
}

.not-all-issue-warning.open-issue-list {
  background-color: #f3f3f3;
  box-sizing: border-box;
  display: inline-block;
  padding: 16px 16px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.issues .issue-list {
  /* no math, just a good guess */
  min-width: 640px;
  width: 800px;
}

.issues .issue a:focus,
.issues .issue button:focus {
  box-shadow: none;
}

@media (max-width: 1320px) {
  .issues .issue-list {
    width: calc(60vw - 40px);
  }
}

.issue-location {
  display: inline-block;
  vertical-align: top;
  line-height: 18px;
  box-sizing: border-box;
  background-color: #f2dede;
  transition: background-color 0.3s ease;
}

.issues-workspace-list-component {
  padding: 15px 0 6px;
}

.issues-workspace-list-item + .issues-workspace-list-item {
  margin-top: 5px;
}

li:first-child .issues-workspace-list-component {
  padding-top: 0;
}

.issues-predefined-periods {
  display: flex;
}

.issues-predefined-periods .search-navigator-facet {
  width: auto;
  margin-right: 4px;
}

.bulk-change-radio-button {
  margin: 0 -4px;
  padding: 0 4px;
}

.bulk-change-radio-button:hover {
  background-color: #f3f3f3;
}

.layout-page-main.open-issue {
  padding-top: 0;
}
